/* eslint-disable @typescript-eslint/no-unsafe-argument */
// @ts-nocheck

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
const isObject = (item: object) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep: <T>(target: T, ...sources: object[]) => T = (
  target,
  ...sources
) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Object.assign(target, {
            [key]: {}
          });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    }
  }

  return mergeDeep(target, ...sources);
};
