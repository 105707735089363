export const routesList = [
  "/",
  "qr-scanner",
  "signup",
  "email-login",
  "signature-login",
  "wallet",
  "settings",
  "payout-config",
  "history",
  "connect",
  "invoice"
] as const;
